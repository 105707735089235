import * as React from "react";
import { FC } from "react";

const Index: FC = () => {
  return (
    <main className="flex h-screen items-center justify-center">
      <h1>Under construction</h1>
    </main>
  );
};

export default Index;
